import clienticon from "../../assets/images/clienticon.png";
import searchicon from "../../assets/images/searchicon.png";
import scanicon from "../../assets/images/scan.png";
import "./Menu.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { appDateFormatWithTime } from "../../utils/mdc";
import Moment from "react-moment";

function Menu() {
  const db = useSelector((state: any) => state.dbSlice);
  const { Settings } = db;

  const lastSyncDate = Settings.find((el) => el.Key === "lastSyncDate");

  return (
    <>
      <div className="flex justify-content-center mb-5 text-gold">
        {lastSyncDate && <Moment format={appDateFormatWithTime}>{lastSyncDate.Value}</Moment>}
      </div>
      <div className="justify-content-center grid">
        <div className="box shadow-8">
          <Link to="/clients">
            <img className="logo" src={clienticon} alt="" />
            <p>CLIENT</p>
          </Link>
        </div>
        <div className="box shadow-8">
          <Link to="/search">
            <img className="logo" src={searchicon} alt="" />
            <p>SEARCH</p>
          </Link>
        </div>
        <div className="box shadow-8">
          <Link to="/scan">
            <img className="logo" src={scanicon} alt="" />
            <p>SCAN</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Menu;
