import { createSlice } from "@reduxjs/toolkit";

export const dbSlice = createSlice({
  name: "mdcdb",
  initialState: {
    Clients: [],
    MatrixDrops: [],
    MeasureResultComputerDrop: [],
    MeasureResultReceipt: [],
    MeasureResults: [],
    Visitations: [],
    Problems: [],
    Settings: [],
    SelectedClient: {},
    SelectedVisitation: {},
    SelectedProblem: {},
    SearchFilterTerm: "",
  },
  reducers: {
    setSelectedClient: (state, action) => {
      state.SelectedClient = action.payload;
    },
    setSelectedVisitation: (state, action) => {
      state.SelectedVisitation = action.payload;
    },
    setDb: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    setSelectedProblem: (state, action) => {
      state.SelectedProblem = action.payload;
    },
    setSearchFilterTerm: (state, action) => {
      state.SearchFilterTerm = action.payload;
    },
    saveScan: (state, action) => {
      state.Visitations.push({ Id: -1 });
      state.MeasureResultComputerDrop = state.MeasureResultComputerDrop.concat(action.payload);
      state.SelectedVisitation = { Id: -1 };
      state.SelectedClient = {};
    },
  },
});

export const {
  setDb,
  setSelectedClient,
  setSelectedVisitation,
  editCount,
  resetCount,
  setSelectedProblem,
  setSearchFilterTerm,
  saveScan,
} = dbSlice.actions;

export default dbSlice.reducer;
