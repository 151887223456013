import { useRef, useState } from "react";
import scan from "../../assets/images/scan.gif";
import HomeImage from "../../assets/images/MM_2D_gold.png";
import "./Scan.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { _lkp, _sfz, _sfzlkp } from "../../utils/mdc";
import { saveScan } from "../../store/dbSlice";

function Scan() {
  const audioRef = useRef(null);
  const [isScanning, setIsScanning] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const db = useSelector((state: any) => state.dbSlice);

  const { MatrixDrops } = db;

  const randomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const scanningClicked = (state) => {
    if ((state && isScanning) || (!state && !isScanning)) return;

    const timeout = 25;
    setIsScanning(state);
    if (state) {
      audioRef.current.play();
      var tmpTimeoutId = setTimeout(() => {
        generateScanReport();
      }, timeout * 1000);

      setTimeoutId(tmpTimeoutId);
    } else {
      clearTimeout(timeoutId);
      setTimeoutId(null);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const selectDrops = (minId: number, maxId: number, intensities: string[]) => {
    if (!intensities || intensities.length == 0) return;

    const drops = MatrixDrops.filter(
      (el) =>
        el.Id >= minId &&
        el.Id <= maxId &&
        el.IsDropCalibrated === 1 &&
        intensities.indexOf(el.Intensity) >= 0
    );

    return drops;
  };

  const generateResultFromSource = (source, count) => {
    const min = 10;
    const max = 98;

    var result = [];
    for (var i = 0; i < source.length && count - i > 0; i++) {
      const num = randomNumber(0, source.length - 1);
      const probability = randomNumber(min, max);

      const matrixDrop = source[num];
      result.push({
        intensity: matrixDrop.Intensity,
        Id: matrixDrop.Id,
        percent: probability,
      });
    }

    return result;
  };

  const getDropsToAddFromIntervalToResult = (
    interval: number[][],
    intensities: string[],
    count: number
  ) => {
    var source = [];

    interval.forEach((el) => {
      const drops = selectDrops(el[0], el[1], intensities);
      source = source.concat(drops);
    });

    var result = generateResultFromSource(source, count);
    return result;
  };

  const getDropsToAddToResult = (
    minId1: number,
    maxId1: number,
    minId2: number,
    maxId2: number,
    minId3: number,
    maxId3: number,
    intensities: string[],
    count: number
  ) => {
    var result = [];
    var source = selectDrops(minId1, maxId1, intensities);
    if (minId2 != maxId2) {
      var tmp1 = selectDrops(minId2, maxId2, intensities);
      source = source.concat(tmp1);
    }
    if (minId3 != maxId3) {
      var tmp2 = selectDrops(minId3, maxId3, intensities);
      source = source.concat(tmp2);
    }
    result = generateResultFromSource(source, count);

    return result;
  };

  const generateScanReport = () => {
    var result = [];
    
    var drops = getDropsToAddToResult(301, 500, 561, 600, 0, 0, _sfz, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(501, 560, 0, 0, 0, 0, _sfz, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(1, 300, 2201, 3000, 0, 0, _sfz, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(3001, 6000, 0, 0, 0, 0, _sfz, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(1, 300, 2201, 3000, 0, 0, _lkp, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(3001, 6000, 0, 0, 0, 0, _lkp, 3);
    result = result.concat(drops);

    drops = getDropsToAddFromIntervalToResult(
      [
        [801, 854],
        [881, 900],
      ],
      _sfzlkp,
      3
    );
    result = result.concat(drops);

    drops = getDropsToAddFromIntervalToResult([[855, 880]], _sfzlkp, 3);
    result = result.concat(drops);

    drops = getDropsToAddFromIntervalToResult(
      [
        [601, 628],
        [670, 700],
        [729, 729],
        [738, 738],
        [743, 743],
        [1701, 2000],
      ],
      _sfzlkp,
      3
    );
    result = result.concat(drops);

    drops = getDropsToAddToResult(629, 669, 0, 0, 0, 0, _sfzlkp, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(701, 714, 781, 800, 0, 0, _sfzlkp, 3);
    result = result.concat(drops);

    drops = getDropsToAddFromIntervalToResult(
      [
        [715, 728],
        [730, 732],
        [734, 737],
        [739, 742],
        [744, 746],
        [766, 780],
      ],
      _sfzlkp,
      3
    );
    result = result.concat(drops);

    drops = getDropsToAddFromIntervalToResult(
      [
        [733, 733],
        [747, 765],
      ],
      _sfzlkp,
      2
    );
    result = result.concat(drops);

    drops = getDropsToAddToResult(1001, 1500, 0, 0, 0, 0, _sfz, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(1001, 1500, 0, 0, 0, 0, _lkp, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(1501, 1700, 0, 0, 0, 0, _sfz, 3);
    result = result.concat(drops);

    drops = getDropsToAddToResult(1501, 1700, 0, 0, 0, 0, _lkp, 3);
    result = result.concat(drops);

    const sortedData = result.sort((a, b) => b.percent - a.percent);
    var measureResult = [];
    sortedData.forEach((el, i) => {
      measureResult.push({
        Id: i * -1,
        DropId: el.Id,
        Percent: el.percent,
        DropIntensity: el.intensity,
        MeasureResultId: -1,
      });
    });

    dispatch(saveScan(measureResult));
    navigate("/result/-1");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="scan">
        <div className="flex justify-content-around mt-6">
          <button
            className={classNames("btn", { disabled: isScanning })}
            onClick={() => scanningClicked(true)}
          >
            Start Scan
          </button>
          <button onClick={goBack}>
            <i className="pi pi-angle-left"></i>
          </button>
          <button
            className={classNames("btn", { disabled: !isScanning })}
            onClick={() => scanningClicked(false)}
          >
            Stop Scan
          </button>
        </div>
        <div className="m-auto">
          <audio
            ref={audioRef}
            src="szignal.mp3"
            controls={true}
            className="hidden"
          />
          {isScanning == false && (
            <div className="home">
              <img className="logo unselectable" src={HomeImage} alt="" />
            </div>
          )}

          {isScanning == true && (
            <div className="scan-holder mt-6">
              <img className="unselectable" src={scan} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Scan;
