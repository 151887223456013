import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedVisitation } from "../../store/dbSlice";
import { appDateFormat } from "../../utils/mdc";
import "./Client.scss";

function Client() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const db = useSelector((state: any) => state.dbSlice);
  const { Visitations, SelectedClient } = db;
  var filteredVisitations = Visitations.filter((el) => el.ClientId === SelectedClient.Id).sort((a, b) =>
    a.Id > b.Id ? -1 : 1
  );

  const onVisitationClicked = (id) => {
    var visitation = Visitations.find((el) => el.Id === id);
    dispatch(setSelectedVisitation(visitation));
    navigate("/result/" + visitation.Id);
  };

  const goToStatistics = () => {
    navigate("/statistics/" + SelectedClient.Id);
  };

  return (
    <div className="client-details flex justify-content-center md:w-3 m-auto mx-3 md:m-auto flex-column">
      <p className="text-gold text-center text-xl">
        {SelectedClient.Lastname} {SelectedClient.Firstname}
      </p>
      <small className="text-gold text-center">Results</small>
      <button className="w-auto text-center align-self-center mt-5 p-3" onClick={() => goToStatistics()}>
        <i className="pi pi-chart-line"></i>
      </button>
      <ul className="p-5 panel shadow-8 flex flex-column">
        {filteredVisitations.map((visitation, i) => {
          return (
            <li key={i} className="mt-2 align-items-center" onClick={() => onVisitationClicked(visitation.Id)}>
              <p>
                <Moment format={appDateFormat}>{visitation.StartTime}</Moment>
              </p>
              <i className="pi pi-angle-right"></i>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Client;
