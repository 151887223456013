import classNames from "classnames";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { appDateFormat, getDropDescription, getDropName, getResultFromMeasure } from "../../utils/mdc";

function Percent() {
  const db = useSelector((state: any) => state.dbSlice);
  const { id } = useParams();
  const { MeasureResultComputerDrop, MeasureResults, MatrixDrops, SelectedClient, SelectedVisitation } = db;

  const measureResult = MeasureResults.find((el) => el.VisitationId === parseInt(id));
  const mdcResult = MeasureResultComputerDrop.filter((el) =>  (measureResult && el.MeasureResultId === measureResult.Id) || el.MeasureResultId === -1);
  const navigate = useNavigate();
  const { result } = getResultFromMeasure(mdcResult, MatrixDrops);

  const isDropInResult = (drop) => {
    const dropId = drop.DropId;
    const inResult = result.find((el) => el.data.find((d) => d && d.DropId === dropId));

    return inResult;
  };

  const goToDrop = (dropId, intensity) => {
    navigate("/matrixdrop/" + dropId + "/" + intensity);
  };

  return (
    <div className="result flex justify-content-center w-full md:w-5 m-auto md:m-auto flex-column">
      <p className="text-gold text-center text-xl">
        {SelectedClient.Lastname} {SelectedClient.Firstname}
      </p>
      <small className="text-gold text-center">
        <Moment format={appDateFormat}>{SelectedVisitation.StartTime}</Moment>
      </small>
      <ul className="panel p-5">
        {mdcResult.map((drop, i) => {
          const isInResult = isDropInResult(drop);

          return (
            <li
              key={i}
              className={classNames("grid", { "font-bold": isInResult })}
              onClick={() => goToDrop(drop.DropId, drop.DropIntensity)}
            >
              <div className="col-3 md:col-2">{getDropName(drop)}</div>
              <div className="col-2 text-center">{drop.Percent}%</div>
              <div className="col-7 md:col-8">{getDropDescription(MatrixDrops, drop)}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Percent;
