import { useDispatch, useSelector } from "react-redux";
import { ListBox } from "primereact/listbox";

import "./Search.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setSearchFilterTerm, setSelectedProblem } from "../../store/dbSlice";
import classNames from "classnames";
import { removeAccents } from "../../utils/mdc";

function Search() {
  const dispatch = useDispatch();
  const db = useSelector((state: any) => state.dbSlice);
  const navigate = useNavigate();
  const { Problems, SelectedProblem, SearchFilterTerm, MatrixDrops } = db;

  const onListSelect = (id) => {
    dispatch(setSelectedProblem(id));
  };

  const onFilterValueChange = (val) => {
    dispatch(setSearchFilterTerm(val));
    dispatch(setSelectedProblem(null));
  };

  const problem = SelectedProblem && Problems.length > 0 ? Problems.find((el) => el.Id === SelectedProblem) : null;
  const searchDrops =
    SearchFilterTerm.length >= 3 &&
    MatrixDrops.filter(
      (el) =>
        removeAccents(el.Mind).toLowerCase().indexOf(removeAccents(SearchFilterTerm).toLowerCase()) >= 0 ||
        removeAccents(el.Body).toLowerCase().indexOf(removeAccents(SearchFilterTerm).toLowerCase()) >= 0
    );

  const getDropsList = () => {
    var list = [];
    if (problem) {
      problem.DropsText.split(",").map((dropText) => {
        list.push(dropText);
      });
    }

    return list;
  };

  const goToDrops = (dropText) => {
    const dropsParts = dropText.trim().split(" ");
    navigate("/matrixdrop/" + dropsParts[0] + "/" + dropsParts[1]);
  };

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span key={i} className={classNames({ highlight: part.toLowerCase() === highlight.toLowerCase() })}>
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  var resultList = getDropsList();

  return (
    <div className="search grid justify-content-center w-full md:w-8 m-auto md:m-auto">
      <div className="col-12 md:col-6">
        <ListBox
          value={SelectedProblem}
          options={Problems}
          optionLabel="Name"
          optionValue="Id"
          filter
          listStyle={{ height: "250px" }}
          virtualScrollerOptions={{ itemSize: 38 }}
          className="shadow-8"
          onChange={(e) => onListSelect(e.value)}
          onFilterValueChange={(e) => onFilterValueChange(e.value)}
          filterValue={SearchFilterTerm}
        />
        <div className="result panel shadow-8">
          <p className="title p-3">Keresések erdeménye</p>
          <div className="p-4 grid">
            {problem &&
              resultList.map((drop, i) => (
                <React.Fragment key={i}>
                  <div className="drop pr-4 font-bold pb-2" onClick={() => goToDrops(drop)}>
                    {drop}
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
      <div className="col-12 md:col-6">
        <div className="panel info shadow-8 p-3">
          {searchDrops &&
            searchDrops.map((el, i) => (
              <React.Fragment key={i}>
                <div className="font-bold p-2 drop" onClick={() => goToDrops(el.Name)}>
                  {el.Name}
                </div>
                {removeAccents(el.Mind).toLowerCase().indexOf(removeAccents(SearchFilterTerm).toLowerCase()) >= 0 && (
                  <div className="pt-1 pb-1 description">{getHighlightedText(el.Mind, SearchFilterTerm)}</div>
                )}
                {removeAccents(el.Body).toLowerCase().indexOf(removeAccents(SearchFilterTerm).toLowerCase()) >= 0 && (
                  <div className="pt-1 pb-1 description">{getHighlightedText(el.Body, SearchFilterTerm)}</div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Search;
