import "./App.scss";
import "primeflex/primeflex.css";

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Clients from "./pages/Clients/Clients";
import Client from "./pages/Client/Client";
import { useSelector } from "react-redux";
import Menu from "./pages/Menu/Menu";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import Result from "./pages/Result/Result";
import MatrixDrop from "./pages/MatrixDrop/MatrixDrop";
import Layout from "./layouts/Layout";
import "moment/locale/hu";
import Search from "./pages/Search/Search";
import Percent from "./pages/Percent/Percent";
import Statistics from "./pages/Statistics/Statistics";
import Scan from "./pages/Scan/Scan";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/clients"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/clients" element={<Clients />} />
        </Route>

        <Route
          path="/client/:id"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/client/:id" element={<Client />} />
        </Route>

        <Route
          path="/menu"
          element={
            <>
              <Layout hideBackButtons={true} />
            </>
          }
        >
          <Route path="/menu" element={<Menu />} />
        </Route>

        <Route
          path="/result/:id"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/result/:id" element={<Result />} />
        </Route>

        <Route
          path="/matrixdrop/:id/:intensity"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/matrixdrop/:id/:intensity" element={<MatrixDrop />} />
        </Route>

        <Route
          path="/search"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/search" element={<Search />} />
        </Route>

        <Route
          path="/percent/:id"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/percent/:id" element={<Percent />} />
        </Route>

        <Route
          path="/statistics/:id"
          element={
            <>
              <Layout />
            </>
          }
        >
          <Route path="/statistics/:id" element={<Statistics />} />
        </Route>
        <Route path="/scan" element={<Scan />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

function DbCheckOutlet() {
  const db = useSelector((state: any) => state.dbSlice);
  return db.Clients.length > 0 ? <Outlet /> : <Navigate to="/" />;
}

export default App;
