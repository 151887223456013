export const _sfz = ["Sárga", "Zöld", "Fehér"];
export const _lkp = ["Lila", "Kék", "Piros"];
export const _sfzlkp = ["Sárga", "Zöld", "Fehér", "Lila", "Kék", "Piros"];
export const appDateFormat = "dddd, YYYY MMMM D";
export const appDateFormatWithTime = "dddd, YYYY MMMM D, HH:mm";
export const appDateFormatShort = "YYYY MMMM D";

export const getMd = (list, groups, countRequired, intensities = []) => {
  var dropResultList = [];

  var listDone = false;

  list.every((element) => {
    groups.forEach((group) => {
      const minDropNum = group[0];
      const maxDropNum = group[1];
      if (
        element.DropId >= minDropNum &&
        element.DropId <= maxDropNum &&
        (intensities.length === 0 || intensities.indexOf(element.DropIntensity) >= 0)
      ) {
        dropResultList.push(element);
        if (dropResultList.length == countRequired) {
          listDone = true;
          return false;
        }
      }
    });
    return !listDone;
  });
  return dropResultList;
};

export const getDrop = (list, id) => {
  return list.find((el) => el.Id === id);
};

export const getGeoDrops = (matrixDropsList, list) => {
  var result = [];
  list.every((element) => {
    const drop = getDrop(matrixDropsList, element.DropId);
    if (drop.IsGeo === 1) {
      result.push(element);
    }
    return true;
  });

  return result;
};

export const getEntityDrops = (matrixDropsList, list) => {
  var result = [];
  list.every((element) => {
    const drop = getDrop(matrixDropsList, element.DropId);
    if (drop.IsEntity === 1 && element.Percent > 50) {
      result.push(element);
      if (result.length === 3) return false;
    }
    return true;
  });

  return result;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export const removeAccents = (str) => {
  return str;

  if (str && str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, "A")
      .replace(/[\xC6]/g, "AE")
      .replace(/[\xC7]/g, "C")
      .replace(/[\xC8-\xCB]/g, "E")
      .replace(/[\xCC-\xCF]/g, "I")
      .replace(/[\xD0]/g, "D")
      .replace(/[\xD1]/g, "N")
      .replace(/[\xD2-\xD6\xD8]/g, "O")
      .replace(/[\xD9-\xDC]/g, "U")
      .replace(/[\xDD]/g, "Y")
      .replace(/[\xDE]/g, "P")
      .replace(/[\xE0-\xE5]/g, "a")
      .replace(/[\xE6]/g, "ae")
      .replace(/[\xE7]/g, "c")
      .replace(/[\xE8-\xEB]/g, "e")
      .replace(/[\xEC-\xEF]/g, "i")
      .replace(/[\xF1]/g, "n")
      .replace(/[\xF2-\xF6\xF8]/g, "o")
      .replace(/[\xF9-\xFC]/g, "u")
      .replace(/[\xFE]/g, "p")
      .replace(/[\xFD\xFF]/g, "y");
  }

  return str;
};

export const getDropName = (drop) => {
  return drop.DropId + " " + drop.DropIntensity[0];
};

export const getDropDescription = (MatrixDrops, drop) => {
  const mdcDrop = MatrixDrops.find((el) => el.Id === (drop.DropId || drop));
  return mdcDrop ? mdcDrop.AliasName : "";
};

export const getResultFromMeasure = (mdcResult, MatrixDrops) => {
  const md1 = getMd(
    mdcResult,
    [
      [301, 500],
      [561, 600],
    ],
    2,
    _sfz
  );

  const md2 = getMd(mdcResult, [[501, 560]], 1, _sfz);
  const md3 = getMd(mdcResult, [[1501, 1700]], 1, _sfz);
  const md4 = getMd(mdcResult, [[1501, 1700]], 1, _lkp);
  const md5 = getMd(
    mdcResult,
    [
      [801, 854],
      [881, 900],
    ],
    1,
    _sfzlkp
  );
  const md6 = getMd(mdcResult, [[1001, 1500]], 1, _sfz);
  const md7 = getMd(
    mdcResult,
    [
      [1, 300],
      [2201, 3000],
    ],
    1,
    _sfz
  );
  const md8 = getMd(mdcResult, [[3001, 6000]], 1, _sfz);
  const md9 = getMd(
    mdcResult,
    [
      [701, 714],
      [781, 800],
    ],
    1,
    _sfzlkp
  );
  const md10 = getMd(
    mdcResult,
    [
      [715, 728],
      [730, 737],
      [739, 742],
      [744, 746],
      [766, 780],
    ],
    1,
    _sfzlkp
  );
  const md11 = getMd(mdcResult, [[747, 765]], 1, _sfzlkp);
  const md12 = getMd(
    mdcResult,
    [
      [601, 628],
      [670, 700],
      [729, 729],
      [738, 738],
      [743, 743],
      [1701, 2000],
    ],
    1,
    _sfzlkp
  );
  const md13 = getMd(mdcResult, [[629, 669]], 1, _sfzlkp);
  const md14 = getMd(mdcResult, [[855, 880]], 1, _sfzlkp);
  const md15 = getMd(
    mdcResult,
    [
      [1, 300],
      [2201, 3000],
    ],
    1,
    _lkp
  );
  const md16 = getMd(mdcResult, [[1001, 1500]], 1, _lkp);
  const md17 = getMd(mdcResult, [[3001, 6000]], 1, _sfzlkp);

  const geo = getGeoDrops(MatrixDrops, mdcResult);
  const entity = getEntityDrops(MatrixDrops, mdcResult);

  const getDropByIndex = (dropArray, index = 0) => {
    if (dropArray.length > index) return dropArray[index];
    return null;
  };

  const result = [
    {
      title: "I. Jelenlegi információs minták",
      data: [getDropByIndex(md1), getDropByIndex(md1, 1), getDropByIndex(md2)],
    },
    {
      title: "II. Vitaminok, allergiák, nehézfémek információs mintái",
      data: [getDropByIndex(md9), getDropByIndex(md10), getDropByIndex(md11)],
    },
    {
      title: "III. Aminosavak és acidumok informácós mintái",
      data: [getDropByIndex(md3), getDropByIndex(md4)],
    },
    {
      title: "IV. Környezetból érkező információs minták",
      data: [getDropByIndex(md12), getDropByIndex(md13), getDropByIndex(md14)],
    },
    {
      title: "V. Fizikai szintű blokkok, elakadások infromácios mintái",
      data: [getDropByIndex(md5), getDropByIndex(md6), getDropByIndex(md7), getDropByIndex(md8)],
    },
    {
      title: "VI. Periódusos rendeszer elemei - sorsfeladat és alkat",
      data: [getDropByIndex(md16), getDropByIndex(md15), getDropByIndex(md17)],
    },
  ];

  return { result, geo, entity };
};
