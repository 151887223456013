import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import mdlogo from "../assets/images/MM_2D_gold.png";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function Layout(props) {
  const db = useSelector((state: any) => state.dbSlice);
  const navigate = useNavigate();
  const [sidebarVisible, setSideBarVisible] = useState(false);

  const { hideBackButtons } = props;
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (db.Clients.length === 0) navigate("/");
  }, []);

  return (
    <>
      <div>
        <div className="top-bar fixed">
          <i className="pi pi-bars text-gold p-4 " onClick={() => setSideBarVisible(true)}></i>
        </div>
        <Sidebar visible={sidebarVisible} onHide={() => setSideBarVisible(false)}>
          <ul>
            <li>
              <Link to="/menu" onClick={() => setSideBarVisible(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/clients" onClick={() => setSideBarVisible(false)}>
                Clients
              </Link>
            </li>
            <li>
              <Link to="/Search" onClick={() => setSideBarVisible(false)}>
                Search
              </Link>
            </li>
          </ul>

          <Link
            className="btn m-4 text-center justify-content-center"
            to="/"
            onClick={() => {
              setSideBarVisible(false);
              window.location.reload();
            }}
          >
            Start
            <i className="pi pi-sync pl-2"></i>
          </Link>
        </Sidebar>
        <div className="py-8">
          <div className="flex justify-content-center mb-5">
            <Link to="/menu">
              <img className="logo" src={mdlogo} alt="" />
            </Link>
          </div>
          <div className="flex justify-content-center mb-5">
            {!hideBackButtons && (
              <button onClick={goBack}>
                <i className="pi pi-angle-left"></i>
              </button>
            )}
          </div>
          <Outlet />
          <div className="flex justify-content-center mt-5">
            {!hideBackButtons && (
              <button onClick={goBack}>
                <i className="pi pi-angle-left"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
