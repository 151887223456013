import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedVisitation } from "../../store/dbSlice";
import { appDateFormat, appDateFormatWithTime, getDropDescription, getDropName } from "../../utils/mdc";

function Statistics() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const db = useSelector((state: any) => state.dbSlice);
  const { SelectedClient, Visitations, MeasureResults, MeasureResultComputerDrop, MatrixDrops } = db;

  var clientVisitations = Visitations.filter((el) => el.ClientId === SelectedClient.Id);

  var statistics = {};

  clientVisitations.map((visitation, i) => {
    const measureResult = MeasureResults.find((el) => el.VisitationId === parseInt(visitation.Id));
    const mdcResult = MeasureResultComputerDrop.filter((el) => el.MeasureResultId === measureResult.Id);

    mdcResult.map((result, j) => {
      const dropId = result.DropId;
      if (!statistics[dropId]) {
        statistics[dropId] = [];
      }
      statistics[result.DropId].push({ measure: result, visitation: measureResult });
    });
  });

  const goToDrop = (dropId, intensity) => {
    navigate("/matrixdrop/" + dropId + "/" + intensity);
  };

  const onVisitationClicked = (id) => {
    var visitation = Visitations.find((el) => el.Id === id);
    dispatch(setSelectedVisitation(visitation));
    navigate("/result/" + visitation.Id);
  };

  //statistics = Object.fromEntries(Object.entries(statistics).sort(([, a], [, b]) => b.length - a.length));

  const statisticsKeys = Object.keys(statistics).sort((a, b) => (statistics[a].length > statistics[b].length ? -1 : 1));

  return (
    <div className="result flex justify-content-center w-full md:w-5 m-auto md:m-auto flex-column">
      <p className="text-gold text-center text-xl">
        {SelectedClient.Lastname} {SelectedClient.Firstname}
      </p>
      <ul className="panel md:p-5 p-0">
        {statisticsKeys.map((dropId, i) => {
          return (
            <>
              <li onClick={() => goToDrop(dropId, "F")}>
                <p>
                  {dropId} {getDropDescription(MatrixDrops, parseInt(dropId))}
                  <i className="ml-2 pi pi-angle-right"></i>
                </p>
              </li>
              {statistics[dropId].map((drop, j) => {
                return (
                  <li onClick={() => onVisitationClicked(drop.visitation.VisitationId)}>
                    <p className="ml-6">
                      {getDropName(drop.measure)} - {drop.measure.Percent}% -{" "}
                      <Moment format={appDateFormat}>{drop.visitation.Date}</Moment>
                    </p>
                  </li>
                );
              })}
            </>
          );
        })}
      </ul>
    </div>
  );
}

export default Statistics;
