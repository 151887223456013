import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { scrollToTop } from "../../utils/mdc";
import "./MatrixDrop.scss";

function MatrixDrop() {
  const { id, intensity } = useParams();
  const db = useSelector((state: any) => state.dbSlice);
  const { MatrixDrops } = db;
  const drop = MatrixDrops.find((el) => el.Id === parseInt(id));

  const getDropName = (dropText) => {
    const dropParts = dropText.trim().split(" ");
    return dropParts[0] + " " + intensity[0];
  };

  useEffect(() => {
    scrollToTop();
  });

  return (
    <div className="matrixdrop grid justify-content-center w-full md:w-8 m-auto md:m-auto">
      <p className="header p-2">{getDropName(drop.Name)}</p>
      <div className="panel col-12">
        <p className="title p-3">Ásványok és esszenciák</p>
        <div className="content">{drop.Minerals}</div>
      </div>
      <div className="panel col-12 md:col-6 mt-3">
        <p className="title p-3 text-right">Mind</p>
        <div className="content">{drop.Mind}</div>
      </div>
      <div className="panel col-12 md:col-6 mt-3">
        <p className="title p-3 text-right">Body</p>
        <div className="content">{drop.Body}</div>
      </div>
    </div>
  );
}

export default MatrixDrop;
