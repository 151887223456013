import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import "./Clients.scss";
import { useState } from "react";
import { setSelectedClient } from "../../store/dbSlice";
import Moment from "react-moment";
import { appDateFormat, appDateFormatShort } from "../../utils/mdc";

function Clients() {
  const dispatch = useDispatch();
  const db = useSelector((state: any) => state.dbSlice);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const onClientClicked = (id) => {
    var client = db.Clients.find((el) => el.Id === id);
    if (client) {
      dispatch(setSelectedClient(client));
      navigate("/client/" + id);
    }
  };
  const filterredClients = db.Clients.filter(
    (el) =>
      filterText === "" ||
      el.Lastname.toLowerCase().indexOf(filterText) >= 0 ||
      el.Firstname.toLowerCase().indexOf(filterText) >= 0
  );

  return (
    <div className="clients flex justify-content-center md:w-3 m-auto  mx-3 md:m-auto flex-column">
      {filterredClients.length > 0 && (
        <InputText
          className="mb-4 shadow-8"
          name="filter"
          placeholder="Search"
          onChange={(e) => setFilterText(e.target.value.toLowerCase())}
        />
      )}
      <div className="panel shadow-8">
        {filterredClients.length > 0 && (
          <ul className="p-5 flex flex-column">
            {filterredClients.map((client, i) => {
              return (
                <li key={i} className="mt-2 align-items-center" onClick={() => onClientClicked(client.Id)}>
                  <div>
                    <p className="mb-0">
                      {client.Id}. {client.Lastname} {client.Firstname}
                    </p>
                    <p className="text-xs m-0 ml-4 mb-2">
                      <Moment format={appDateFormatShort}>{client.Birthday}</Moment>
                    </p>
                  </div>
                  <i className="pi pi-angle-right"></i>
                </li>
              );
            })}
          </ul>
        )}
        {filterredClients.length === 0 && <p>No clients found</p>}
      </div>
    </div>
  );
}

export default Clients;
