import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  appDateFormat,
  getDropDescription,
  getDropName,
  getResultFromMeasure,
  _lkp,
  _sfz,
  _sfzlkp,
} from "../../utils/mdc";
import "./Result.scss";

function Result() {
  const navigate = useNavigate();
  const db = useSelector((state: any) => state.dbSlice);
  const { SelectedClient, SelectedVisitation, MeasureResultComputerDrop, MeasureResults, MatrixDrops } = db;
  const visitationId = SelectedVisitation.Id;
  const measureResult = MeasureResults.find((el) => el.VisitationId === visitationId);
  const mdcResult = MeasureResultComputerDrop.filter((el) =>  (measureResult && el.MeasureResultId === measureResult.Id) || el.MeasureResultId === -1);

  const { result, geo, entity } = getResultFromMeasure(mdcResult, MatrixDrops);
  const goToDrop = (dropId, intensity) => {
    navigate("/matrixdrop/" + dropId + "/" + intensity);
  };

  const goToPercent = (visitationId) => {
    navigate("/percent/" + visitationId);
  };

  return (
    <div className="result flex justify-content-center w-full md:w-8 m-auto md:m-auto flex-column">
      <>
        <p className="text-gold text-center text-xl">
          {SelectedClient.Lastname} {SelectedClient.Firstname}
        </p>
        <small className="text-gold text-center">
          <Moment format={appDateFormat}>{SelectedVisitation.StartTime}</Moment>
        </small>
        <button className="w-auto text-center align-self-center mt-5 p-3" onClick={() => goToPercent(visitationId)}>
          <i className="pi pi-percentage"></i>
        </button>

        <div className="panels mt-5">
          <div className="grid m-3">
            {result.map((element, i) => {
              return (
                <div key={i} className="col-12 md:col-6">
                  <div className="panel shadow-8 p-3">
                    <p className="title">{element["title"]}</p>
                    <div className="list">
                      {element["data"].map((drop, j) => {
                        return (
                          drop && (
                            <div
                              key={j}
                              className="row click"
                              onClick={() => goToDrop(drop.DropId, drop.DropIntensity)}
                            >
                              <div className="col-2">{getDropName(drop)}</div>
                              <div className="col-2 text-center">{drop.Percent}%</div>
                              <div className="col-8">{getDropDescription(MatrixDrops, drop)}</div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="col-12 md:col-6">
              <div className="panel shadow-8 p-3">
                <div className="flex flex-column md:flex-row">
                  <div className="col-12 md:col-4 text-violet-dark font-bold">GEOPATHIC STRESS:</div>
                  <div className="col-12 md:col-8">
                    {geo.map((drop, i) => {
                      return (
                        <div key={i} className="flex">
                          <span className="pr-3 w-5rem md:w-max">{getDropName(drop)}</span>
                          <span className="pr-3">{drop.Percent + "%"}</span>
                          <span className="pr-3">{getDropDescription(MatrixDrops, drop)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="panel shadow-8 p-3">
                <div className="flex flex-column md:flex-row">
                  <div className="col-12 md:col-4 text-violet-dark font-bold">ENTITY:</div>
                  <div className="col-12 md:col-8">
                    {entity.map((drop) => drop.DropId + " " + drop.Percent + "%").join("; ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Result;
