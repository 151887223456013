import HomeImage from "../../assets/images/MM_2D_gold.png";
import "./Home.scss";
import { useRef, useState } from "react";
import initSqlJs from "sql.js";
import { useDispatch } from "react-redux";
import { setDb } from "../../store/dbSlice";
import history from "../../utils/history";
import { useNavigate } from "react-router-dom";

function Home() {
  var fileInputEl = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const config = {
    locateFile: (filename: any) => `/${filename}`,
  };

  const loadDb = (dbFile: any) => {
    initSqlJs(config).then(async function (SQL) {
      const db = new SQL.Database(dbFile);

      setIsLoading(true);
      const tables = [
        "Clients",
        "MatrixDrops",
        "MeasureResultComputerDrop",
        "MeasureResultReceipt",
        "MeasureResults",
        "Visitations",
        "Problems",
        "Settings",
      ];

      tables.forEach((table) => {
        const rows = exportTable(db, table);
        dispatch(setDb({ key: table, data: rows }));
      });

      setIsLoading(false);
      navigate("/menu");
    });
  };

  const exportTable = (db, tablename) => {
    var stmt = db.prepare("SELECT * FROM " + tablename);
    var rows = [];

    while (stmt.step()) {
      const row = stmt.getAsObject();
      rows.push(row);
    }

    return rows;
  };

  const onNewFileSelected = (e: any) => {
    setIsLoading(true);
    const file = e.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = function (e) {
        if (reader.result != null && typeof reader.result !== "string") {
          let arrayBuffer = new Uint8Array(reader.result);
          loadDb(arrayBuffer);

          //fileInputEl.state.files = [];
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const onLogoClicked = () => {
    if (fileInputEl !== null && fileInputEl.current !== null) {
      fileInputEl.current.click();
    }
  };

  return (
    <div className="home flex align-items-center justify-content-center">
      {isLoading === false && (
        <>
          <div>
            <img className="logo unselectable" src={HomeImage} alt="" onClick={onLogoClicked} />
          </div>
          <input
            type="file"
            name="dbfile"
            accept=".db"
            onChange={(e) => onNewFileSelected(e)}
            className="hidden"
            ref={fileInputEl}
          />
          {false && <i className="pi pi-replay text-xl" onClick={() => window.location.reload()}></i>}
        </>
      )}
      {isLoading && <i className="pi pi-spin pi-spinner text-3xl text-white"></i>}
    </div>
  );
}

export default Home;
